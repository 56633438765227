<template>
	<w-matrix
		:headers="headers"
		:loading="loadings > 0"
		:no-data-text="noDataText"
		:pagination.sync="pagination"
		:total-items="totalCompanies"
		:value="companies"
	>
		<template v-for="(user, index) in filteredUsersList" v-slot:[getHeaderSlotName(index)]>
			<th :key="index + 1">
				<w-layout align-center column justify-center>
					<w-checkbox
						color="secondary"
						:disabled="!canCheck"
						false-value="none"
						hide-details
						:indeterminate="user.status === 'some'"
						:ripple="false"
						style="width: 24px"
						true-value="all"
						:value="user.status"
						@input="onUserCompaniesAssignmentChange($event, user.id)"
					/>
					<w-flex text-xs-center v-text="user.username ? user.username : user.email" />
				</w-layout>
			</th>
		</template>
		<template v-slot:items="{ row }">
			<td class="text-xs-left">
				<w-layout align-center justify-start row>
					<w-flex mr-2 shrink>
						<w-checkbox
							color="secondary"
							:disabled="!canCheck"
							false-value="none"
							hide-details
							:indeterminate="row.status === 'some'"
							:ripple="false"
							style="width: 24px"
							true-value="all"
							:value="row.status"
							@input="onUsersCompanyAssignmentChange($event, row.id)"
						/>
					</w-flex>
					<w-flex shrink v-text="row.name" />
				</w-layout>
			</td>
			<td v-for="(user, index) in filteredUsersList" :key="index" class="text-xs-center">
				<w-layout align-center column justify-center>
					<w-checkbox
						:key="`${user.id}-${row.id}`"
						:value="row.users.includes(user.id)"
						:disabled="!canCheck"
						@input="onUserCompanyAssignmentChange($event, row.id, user.id)"
					/>
				</w-layout>
			</td>
		</template>
	</w-matrix>
</template>

<script>
import CompanyAssignmentModuleGuard from '@/mixins/ModulesGuards/TeamManager/CompanyAssignmentModuleGuard'

export default {
	name: 'CompanyAssignmentMatrix',
	mixins: [CompanyAssignmentModuleGuard],
	data: function () {
		return {
			companies: [],
			loadings: 0,
			pagination: {
				page: 1,
				rowsPerPage: 15
			},
			totalCompanies: 0,
			isError: false
		}
	},
	computed: {
		canCheck: function () {
			return this.service.rights.canCheck()
		},
		filteredUsersList: function () {
			let result = []
			if (this.selectedUsers.length === 0) {
				result = [...this.users]
			} else {
				result = this.users.filter(user => this.selectedUsers.includes(user.id))
			}
			return result
		},
		filters: function () {
			return this.service.getFilters()
		},
		headers: function () {
			let result = [
				{
					text: this.$t('team-manager.company-assignment.customers')
				}
			]
			this.filteredUsersList.forEach(user => {
				result.push({
					text: user.username ?? user.email
				})
			})
			return result
		},
		noDataText: function () {
			let result = null
			if (this.loadings > 0) {
				result = this.$t('team-manager.companies.loading-progress')
			} else if (this.filters.search) {
				result = this.$t('team-manager.companies.no-data-found-for-search', { search: this.filters.search })
			} else if (this.isError) {
				result = this.$t('team-manager.companies.errors.error_loading_companies')
			} else {
				result = this.$t('team-manager.companies.no-data-found')
			}
			return result
		},
		selectedUsers: function () {
			return this.service.getSearchedUsers()
		},
		users: function () {
			return this.service.getUsers()
		}
	},
	watch: {
		filters: {
			deep: true,
			handler: function () {
				this.refreshCompanies()
			},
			immediate: true
		},
		pagination: {
			deep: true,
			handler: function (newVal, oldVal) {
				if ((!oldVal && newVal) || newVal.page !== oldVal.page || newVal.rowsPerPage !== oldVal.rowsPerPage) {
					this.getCompanies()
				}
			},
			immediate: true
		},
		accountingFirmId: {
			handler: function (newValue, oldValue) {
				if (newValue && (!oldValue || newValue != oldValue)) {
					this.refreshCompanies()
				}
			}
		}
	},
	methods: {
		getCompanies: function (reset = false) {
			this.isError = false
			this.loadings++
			if (reset) {
				this.companies.clear()
				this.pagination = {
					rowsPerPage: 15,
					page: 1
				}
				this.totalCompanies = 0
			}

			return this.service
				.listCompanies(this.pagination)
				.then(data => {
					this.totalCompanies = data.total

					this.companies.clear()
					this.companies = data.companies
				})
				.catch(err => {
					if (err.message != 'Request cancelled') {
						this.isError = true
						Promise.reject(err)
					}
				})
				.finally(() => {
					this.loadings--
				})
		},
		getHeaderSlotName: function (index) {
			return `header-cell-${index + 1}`
		},
		onUsersCompanyAssignmentChange: function (toggleValue, vendorId) {
			const company = this.companies.find(c => c.id === vendorId)
			const selectedUsers = this.filteredUsersList
			return this.service.changeUsersCompanyAssignment(vendorId, toggleValue).then(() => {
				if (!company) {
					return
				}
				company.status = toggleValue
				if (toggleValue === 'all') {
					selectedUsers.forEach(user => {
						if (!company.users.includes(user.id)) {
							company.users.push(user.id)
						}
					})
					this.users.forEach(user => {
						if (user.status === 'none') {
							user.status = 'some'
						}
					})
				} else {
					company.users.clear()
					this.users.forEach(user => {
						if (user.status === 'all') {
							user.status = 'some'
						}
					})
				}
			})
		},
		reflectUserCompaniesAssignmentChange: function (toggleValue, userId) {
			this.companies.forEach(company => {
				if (toggleValue === 'all' && !company.users.includes(userId)) {
					company.users.push(userId)
					if (company.status === 'none') {
						company.status = 'some'
					}
					if (company.users.length === this.users.length) {
						company.status = 'all'
					}
				} else if (toggleValue === 'none' && company.users.includes(userId)) {
					const index = company.users.findIndex(uId => uId === userId)
					if (index >= 0) {
						company.users.splice(index, 1)
					}
					if (company.users.length === 0) {
						company.status = 'none'
					} else {
						company.status = 'some'
					}
				}
			})
		},
		onUserCompaniesAssignmentChange: function (toggleValue, userId) {
			const foundUser = this.users.find(user => user.id === userId)
			return this.service.changeUserCompaniesAssignment(userId, toggleValue).then(() => {
				if (foundUser) {
					foundUser.status = toggleValue
				}
				this.reflectUserCompaniesAssignmentChange(toggleValue, userId)
			})
		},
		onUserCompanyAssignmentChange: function (toggleValue, vendorId, userId) {
			return this.service.changeUserCompanyAssignment(vendorId, userId, toggleValue).then(() => {
				const foundCompany = this.companies.find(company => company.id === vendorId)
				if (toggleValue) {
					foundCompany.users.push(userId)
					if (foundCompany.users.length === this.users.length) {
						foundCompany.status = 'all'
					} else {
						foundCompany.status = 'some'
					}
				} else {
					const userIndex = foundCompany.users.findIndex(user => user.id === userId)
					if (userIndex !== -1) {
						foundCompany.users.splice(userIndex, 1)
					}
					if (foundCompany.users.length === 0) {
						foundCompany.status = 'none'
					} else {
						foundCompany.status = 'some'
					}
				}
			})
		},
		refreshCompanies: function () {
			return this.getCompanies(true)
		}
	}
}
</script>
